// Copyright 2017 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Object factory for creating autogenerated audio languages.
 */
var oppia = require('AppInit.ts').module;
oppia.factory('AutogeneratedAudioLanguageObjectFactory', [
    function () {
        var AutogeneratedAudioLanguage = function (id, description, explorationLanguage, speechSynthesisCode, speechSynthesisCodeMobile) {
            this.id = id;
            this.description = description;
            this.explorationLanguage = explorationLanguage;
            this.speechSynthesisCode = speechSynthesisCode;
            this.speechSynthesisCodeMobile = speechSynthesisCodeMobile;
        };
        // TODO (ankita240796) Remove the bracket notation once Angular2 gets in.
        /* eslint-disable dot-notation */
        AutogeneratedAudioLanguage['createFromDict'] = (
        /* eslint-enable dot-notation */
        function (autogeneratedAudioLanguageDict) {
            return new AutogeneratedAudioLanguage(autogeneratedAudioLanguageDict.id, autogeneratedAudioLanguageDict.description, autogeneratedAudioLanguageDict.exploration_language, autogeneratedAudioLanguageDict.speech_synthesis_code, autogeneratedAudioLanguageDict.speech_synthesis_code_mobile);
        });
        return AutogeneratedAudioLanguage;
    }
]);
